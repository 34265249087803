import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
import { Plugin } from "vue-responsive-video-background-player";
import VueGtag from "vue-gtag";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faUser,
  faUserSecret,
  faXmark,
  faAngleDown,
  faCaretDown

}
from "@fortawesome/free-solid-svg-icons";


library.add(
  faUserSecret,
  faUser,
  faXmark,
  faAngleDown,
  faCaretDown,

);




const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon)
let globe = ["/pune/", "/pune", "/pune/thankyou/", "/pune/thankyou"];

if (!globe.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "GTM-PJXHHX7" },
  });
}

let puneLocation = ["/pune/", "/pune"];
let puneThankyouPage = ["/pune/thankyou/", "/pune/thankyou"];
if (puneLocation.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "AW-11303512183" },
  });
}

if (puneThankyouPage.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "AW-11303512183/tEJhCIuXhtQYEPfQ940q" },
  });
}

app.use(store).use(router).use(Plugin).mount("#app");
